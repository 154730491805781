<template lang='pug'>
	.bot
		.mb-4
			h2.mr-2 {{ $t('project.bot') }}

		bot-card(
			:status="bot && bot.isActive() ? 'connected' : bot && bot.isError() ? 'noContact' : 'notConnected'"
			:login="bot && bot.username ? bot.username : ''"
			:firstName="bot && bot.firstName ? bot.firstName : ''"
			:image="bot && bot.image ? bot.image.contentUrl  : ''"
			:social="'Telegram'"
		)

		wizard-success
</template>

<script>
import { mapGetters } from 'vuex';
import BotCard from '@/components/Cards/BotCard';
import WizardSuccess from '@/components/Modals/ModalWizardSuccess';
import { STORAGE } from '@/api/storage';

export default {
	name: 'Bot',
	components: {
		BotCard,
		WizardSuccess
	},
	data: () => ({
		tick: 0
	}),
	computed: {
		...mapGetters({
			bot: 'project/bot',
			projectOpened: 'project/opened'
		}),
	},
	methods: {
		showWizardSuccess() {
			if (STORAGE.getBot() && STORAGE.getBot() != null && this.projectOpened && this.projectOpened.isActive() ) {
				this.$bvModal.show('modalstatussuccess');
				STORAGE.setBot(null);
				return;
			}
		}
	},
	watch: {
		projectOpened(n,o) {
			if (this.tick == 0) {
				this.showWizardSuccess();
				this.tick++
			}
		}
	},
}
</script>
